/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "style/layout.less";
import "core-js/modules/es6.set";
import "core-js/modules/es6.map";
import "raf/polyfill";
import React from "react";
import wrapWithProvider from "./src/state/ReduxWrapper";
import { Auth0Provider } from "@auth0/auth0-react";
import posthog from 'posthog-js';

export const onClientEntry = async () => {
  if (typeof window !== 'undefined') {

    if (!process.env.GATSBY_POSTHOG_PROJECT_KEY) {
      console.warn('PostHog API key not found in environment variables');
      return;
    }

    try {
      posthog.init(process.env.GATSBY_POSTHOG_PROJECT_KEY, {
        api_host: process.env.GATSBY_POSTHOG_API_HOST || 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.debug();
        },
        capture_pageview: true,
        persistence: 'localStorage',
        autocapture: false // Disable autocapture
      });
      console.log('PostHog successfully initialized');
    } catch (error) {
      console.error('PostHog initialization error:', error);
    }
  }
};


export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/callback`,
      }}
      useRefreshTokens
      cacheLocation="localstorage">
      {wrapWithProvider({ element })}
    </Auth0Provider>
  );
};

export const onRouteUpdate = ({ prevLocation }) => {
  // Don't fire GTM on first page load
  if (prevLocation === null) return;

  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    if (window) {
      const dataLayer = window.dataLayer;
      if (!dataLayer) {
        window.dataLayer = [];
      }
      window.dataLayer.push({ event: `gatsby-route-change` });
    }
  }, 50);
};
